<template>
    <v-data-table :headers="headers" :items="dataTable" single-expand :expanded.sync="expanded" dense item-key="name"
        :show-expand=false :loading=loadingTable class="elevation-1" items-per-page="50">
        <template v-slot:top>
            <v-toolbar flat>
                <v-form ref="form_toolbar_search">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-menu>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="pt-5" v-model="dateRangeText" label="Intervalo de fechas"
                                            persistent-hint readonly append-icon="mdi-calendar-multiple" v-bind="attrs"
                                            v-on="on" clearable click:clear="dates=''"></v-text-field>
                                    </template>
                                    <v-date-picker range v-model="dates" no-title></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete v-model=accountingMonth class="pt-5" label="Mes contable" :items=months
                                    item-value="number" item-text="name" clearable
                                    click:clear="accountingMonth =''"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete v-model='accountingAccount' class="pt-5" label="Cuentas contables"
                                    :items=accountingAccounts item-text="account_name" item-value="gl_account_id"
                                    :rules=requiredSearch></v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select label='Estado' class="pt-5" v-model=state :items=states item-value='state'
                                    item-text='name'>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-form>
                <v-btn small color="primary" @click="getDataComponent">
                    <v-icon>mdi-magnify</v-icon> Buscar
                </v-btn>
                <v-btn small color="primary" @click="downdoloadReport" class="ml-md-1">
                    <v-icon>mdi-file-excel</v-icon> Descargar
                </v-btn>
            </v-toolbar>
            <v-col cols="12">
                <v-toolbar flat>
                    <v-row>
                        <div class="col-md-2">
                            <b>Cuenta contable:</b> <br /> {{ codeAccountingAccount }}
                        </div>
                        <div class="col-md-2">
                            <b>Saldo inicial:</b> <br /> USD {{ initialBalance.toFixed(2) }}
                        </div>
                        <div class="col-md-2">
                            <b>Periodo contable:</b> <br /> {{ accountingPeriod }}
                        </div>
                        <div class="col-md-2">
                            <b>Débito:</b><br /> USD {{ totalDebits }}
                        </div>
                        <div class="col-md-2">
                            <b>Crédito:</b>
                            <p> USD {{ totalCredits }}</p>
                        </div>
                        <div class="col-md-2">
                            <b>Saldo final:</b>
                            <p> USD {{ finalBalance.toFixed(2) }}</p>
                        </div>
                    </v-row>
                </v-toolbar>
            </v-col>
        </template>
        <template v-slot:item.credit="{ item }">
            {{ item.currency_uom_id + ' ' + (item.debit_credit_flag == 'C' ? item.amount : 0) }}
        </template>
        <template v-slot:item.debit="{ item }">
            {{ item.currency_uom_id + ' ' + (item.debit_credit_flag == 'D' ? item.amount : 0) }}
        </template>

        <template v-slot:item.transaction_date="{ item }">
            {{ item.transaction_date }}
        </template>
        <template v-slot:footer>
            <v-col cols="12">
                <v-toolbar flat>
                    <v-row>
                        <div class="col-md-2">
                            <b>Cuenta contable:</b> <br /> {{ codeAccountingAccount }}
                        </div>
                        <div class="col-md-2">
                            <b>Saldo inicial:</b> <br /> USD {{ initialBalance.toFixed(2) }}
                        </div>
                        <div class="col-md-2">
                            <b>Periodo contable:</b> <br /> {{ accountingPeriod }}
                        </div>
                        <div class="col-md-2">
                            <b>Débito:</b><br /> USD {{ totalDebits }}
                        </div>
                        <div class="col-md-2">
                            <b>Crédito:</b>
                            <p> USD {{ totalCredits }}</p>
                        </div>
                        <div class="col-md-2">
                            <b>Saldo final:</b>
                            <p> USD {{ finalBalance.toFixed(2) }}</p>
                        </div>
                    </v-row>
                </v-toolbar>
            </v-col>
        </template>
        <template v-slot:item.acctg_trans_id="{ item }">
            <!-- <a target="_blank"
                :href="`${urlExternal}/contabilidad/control/EditAcctgTrans?acctgTransId=${item.acctg_trans_id}&organizationPartyId=${partyInternalOrganization}`">
                {{ item.acctg_trans_id }}
            </a> -->
            <a target="_blank"
                :href="`/asientoContableVista/${item.acctg_trans_id}`">
                {{ item.acctg_trans_id }}
            </a>
        </template>

    </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    name: "LedgerWithBlancesComponent",
    data: () => ({
        expanded: [],
        dataTable: [],
        options: {},
        dates: [],
        accountingMonths: [],
        accountingAccounts: [],
        headers: [
            { text: "Nº asiento", value: "acctg_trans_id" },
            { text: "Tipo", value: "desc_acctg_trans_type" },
            { text: "Fecha de asiento", value: "transaction_date" },
            { text: "Descripción", value: "description" },
            { text: "Débito", value: "debit", align: "right" },
            { text: "Crédito", value: "credit", align: "right" },
            { text: "Saldo", value: "balance", align: "right" },
        ],
        states: [
            { state: 'Y', name: 'Procesadas' },
            { state: 'N', name: 'No procesadas' }
        ],
        requiredSearch: [
            v => !!v || 'El campo es requerido'
        ],
        hostname: window.location.protocol + '//' + window.location.hostname,
        partyInternalOrganization: '',
        state: 'Y',
        singleExpand: false,
        accountingMonth: '',
        codeAccountingAccount: '',
        totalDebits: 0,
        totalCredits: 0,
        initialBalance: 0,
        finalBalance: 0,
        accountingAccount: '',
        accountingPeriod: '',
        dominio: ''
    }),
    computed: {
        ...mapState('master', ['loadingTable', 'url', 'months', 'urlExternal']),

        dateRangeText() {
            console.log(this.dates)
            return this.dates.join(' ~ ')
        },
    },
    methods: {
        ...mapMutations('master', ['setUrl', 'setLoadingTable', 'setLoadingBtn', 'setTitleToolbar']),

        ...mapActions('master', ['errorRequest', 'requestApi', 'alertNotification']),

        getAccountingAccounts() {

            this.setUrl('libro-mayor-con-saldos/1')
            this.setLoadingTable(true)
            this.requestApi({
                method: 'PATCH',
                data: {}
            }).then(res => {
                this.accountingAccounts = res.data.glAccount
                console.log(res)
                this.setLoadingTable(false)
            }).catch(() => {

            }).then(() => {
                this.setLoadingTable(false)
            })
        },

        getDataComponent() {

            if (!this.$refs.form_toolbar_search.validate())
                return

            this.setUrl('libro-mayor-con-saldos')
            this.setLoadingTable(true)
            this.requestApi({
                method: 'GET',
                data: {
                    dates: this.dateRangeText,
                    accountingMonth: this.accountingMonth,
                    accountingAccount: this.accountingAccount,
                    state: this.state
                }
            }).then(res => {
                console.log(res.data)
                this.initialBalance = typeof res.data._embedded.libro_mayor_con_saldos[0][1] != 'undefined'
                    ? res.data._embedded.libro_mayor_con_saldos[0][1] : 0

                if (typeof res.data._embedded.libro_mayor_con_saldos[0][0] != 'undefined') {
                    var ib = this.initialBalance
                    this.dataTable = res.data._embedded.libro_mayor_con_saldos[0][0].map((obj) => {

                        let clss = res.data._embedded.libro_mayor_con_saldos[0][6]
                        let balance = 0
                        if (clss == 'ASSET' || clss == 'EXPENSE') {
                            balance = parseFloat(ib) + (obj.debit_credit_flag == 'D' ? parseFloat(obj.amount) : 0) - (obj.debit_credit_flag == 'C' ? parseFloat(obj.amount) : 0)
                        } else {
                            balance = parseFloat(ib) - (obj.debit_credit_flag == 'D' ? parseFloat(obj.amount) : 0) + (obj.debit_credit_flag == 'C' ? parseFloat(obj.amount) : 0)
                        }

                        ib = balance
                        obj.balance = balance.toFixed(2)

                        return obj
                    })
                }

                console.log(this.dataTable)

                let debitsFilter = this.dataTable.filter(e => e.debit_credit_flag == 'D')
                let creditsFilter = this.dataTable.filter(e => e.debit_credit_flag == 'C')

                let debits = debitsFilter.map((obj) => {
                    if (obj.debit_credit_flag === 'D') return obj.amount
                })
                let credits = creditsFilter.map((obj) => {
                    if (obj.debit_credit_flag === 'C') return obj.amount
                })

                if (debits.length > 0) {
                    this.totalDebits = debits.reduce((total, num) => {
                        return parseFloat(total) + parseFloat(num);
                    })
                }
                if (credits.length > 0) {
                    this.totalCredits = credits.reduce((total, num) => {
                        return parseFloat(total) + parseFloat(num);
                    })
                }

                this.codeAccountingAccount = typeof res.data._embedded.libro_mayor_con_saldos[0][0] != 'undefined'
                    ? res.data._embedded.libro_mayor_con_saldos[0][0].account_name : ''


                this.codeAccountingAccount = typeof res.data._embedded.libro_mayor_con_saldos[0][2] != 'undefined'
                    ? res.data._embedded.libro_mayor_con_saldos[0][2] : 0

                this.accountingPeriod = typeof res.data._embedded.libro_mayor_con_saldos[0][3] != 'undefined'
                    ? res.data._embedded.libro_mayor_con_saldos[0][3] : 0

                this.partyInternalOrganization = typeof res.data._embedded.libro_mayor_con_saldos[0][4] != 'undefined'
                    ? res.data._embedded.libro_mayor_con_saldos[0][4] : ''

                this.finalBalance = typeof res.data._embedded.libro_mayor_con_saldos[0][5] != 'undefined'
                    ? res.data._embedded.libro_mayor_con_saldos[0][5] : 0

            }).catch(() => {

            }).then(() => {
                this.setLoadingTable(false)
            })
        },

        downdoloadReport() {
            this.setUrl('libro-mayor-con-saldos')
            this.setLoadingTable(true)
            this.requestApi({
                method: 'GET',
                data: {
                    dates: this.dateRangeText,
                    accountingMonth: this.accountingMonth,
                    accountingAccount: this.accountingAccount,
                    state: this.state,
                    download: true
                }
            }).then((res) => {
                console.log(res)
                this.setLoadingTable(false)
                let link = document.createElement("a");
                link.href = res.data.file;
                link.download = "Reporte libro mayor con saldos " + this.dateRangeText + " " + this.accountingAccount + ".xlsx";
                link.click();
            }).catch((err) => {

            }).then(() => {
                this.overlay = false;
                this.setLoadingTable(false)
            });

        }

    },
    mounted() {
        this.getAccountingAccounts()
        this.setTitleToolbar('LIBRO MAYOR CON SALDOS')
    }
};
</script>